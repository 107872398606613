import React from 'react';
import './styles/footer.scss';
const Footer = () => {
    return (
        <div className="Footer flex-parent-column small-margin">
            <span className="line"></span>
            <footer className="flex-child-row medium-gap">
                <p>© 2025 The New Park Times</p>
                <p><a href="https://www.linkedin.com/in/parker-anderson-baab0820a" target="_blank" rel="noopener noreferrer">LinkedIn</a></p>
                <p><a href="https://github.com/parkeranderson130" target="_blank" rel="noopener noreferrer">GitHub</a></p>
                <p><a href="https://drive.google.com/file/d/1qdanfjeTAxatYW1Dtb1fuG3fH09gg0k2/view" target="_blank" rel="noopener noreferrer">Resume</a></p>
            </footer>
        </div>
    )
}

export default Footer;
