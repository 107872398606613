import React from 'react';
import '../classes.scss';
import { useNavigate } from 'react-router-dom';
import projects from '../assets/json/projects.json';

const Projects = () => {
    const navigate = useNavigate();

    return (
        <div className="Projects flex-body-column">
            <div className="flex-parent-row flex-wrap-center medium-gap">
                <div className="project flex-child-column small-gap" onClick={() => navigate('/projects/ugascs')}> 
                    <img src={require('../assets/images/ugascs.jpg')} alt="UGA SCS" className="border" />
                    <p>PERSONAL PROJECTS</p>
                    <h3>UGA SCS - Custom CTFs and cybersecurity lectures</h3>
                </div>
                <div className="project flex-child-column small-gap" onClick={() => navigate('/projects/dod')}>
                    <img src={require('../assets/images/dod.jpg')} alt="Department of Defense" className="border" />
                    <p>WORK EXPERIENCE</p>
                    <h3>Department of Defense - Project CHIMERA</h3>
                </div>
                <div className="project flex-child-column small-gap" onClick={() => navigate('/projects/kidchess')}>
                    <img src={require('../assets/images/kidchess.jpg')} alt="KidChess" className="border" />
                    <p>WORK EXPERIENCE</p>
                    <h3>Kid Chess - An online chess campaign</h3>
                </div>
                <div className="project flex-child-column small-gap" onClick={() => navigate('/projects/internsconnected')}>
                    <img src={require('../assets/images/internsconnected.jpg')} alt="InternsConnected" className="border" />
                    <p>WORK EXPERIENCE</p>
                    <h3>InternsConnected - A complete breakdown in website redesign</h3>
                </div>
                <div className="project flex-child-column small-gap" onClick={() => navigate('/projects/research')}>     
                    <img src={require('../assets/images/research.jpg')} alt="Research" className="border" />
                    <p>RESEARCH EXPERIENCE</p>
                    <h3>Smart Manufacturing - Project STREAM</h3>
                </div>
            </div>
            
        </div>
    )
}

export default Projects;