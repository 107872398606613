import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isSubmitting) return;
        
        setIsSubmitting(true);
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);

        emailjs.send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,   // Service ID
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,  // Template ID
            {
                from_name: data.name,
                from_email: data.email,
                message: data.message,
            },
            process.env.REACT_APP_EMAILJS_USER_ID       // User ID
        )
        .then((response) => {
            console.log('Email sent successfully!', response.status, response.text);
            alert('Email sent successfully!');
            e.target.reset();

            setTimeout(() => setIsSubmitting(false), 30000);
        }, (error) => {
            console.error('Failed to send email:', error);
            alert('Failed to send email.');
            setIsSubmitting(false);
        });
    };

    return (
        <div className="Contact flex-body-column">
            <div className="flex-parent-column">
                <h1>Contact</h1>
                <form onSubmit={handleSubmit} className="form flex-parent-column small-gap">
                    <input type="text" name="name" placeholder="Name" required />
                    <input type="email" name="email" placeholder="Email" required />
                    <textarea name="message" placeholder="Message" required />
                    <button type="submit" className="button" disabled={isSubmitting}>
                        {isSubmitting ? 'Please wait...' : 'Send'}
                    </button>
                </form>  
            </div>
        </div>
    );
};

export default Contact;
