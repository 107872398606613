import React from 'react';
import { useLocation, useParams, Navigate } from 'react-router-dom';
import projects from '../assets/json/projects.json';

const ProjectFocus = () => {
    const { title } = useParams();
    const project = projects[title];

    if (!project) {
        return <Navigate to="/projects" />;
    }

    const { projectTitle, projectDescription } = project;

    return (
        <div className="ProjectFocus flex-body-row large-gap">
            <div className="flex-parent-column medium-gap">
                <img src={require(`../assets/images/${title}.jpg`)} alt={title} className="border" />
                <div className="flex-child-row flex-wrap-center sub-gap">
                    {project.skills.map(skill => (
                        <div className="skill">{skill}</div>
                    ))}
                </div>
            </div>
            <div className="flex-parent-column">
                <h2>{projectTitle}</h2>
                <span className="line"></span>
                <p>{projectDescription}</p>
            </div>
        </div>
    );
};

export default ProjectFocus;