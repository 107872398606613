import React from 'react';
import '../classes.scss';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const navigate = useNavigate();

    return (
        <div className="Home flex-body-row large-gap">
            <div>
                <div className="headliner flex-parent-row medium-gap medium-margin">
                    <p className="flex-child-column">
                        I'm Parker Anderson, a Computer Science, Cognitive Science, and Math student at the University of Georgia. 
                        I'm interested in the intersection of technology and human cognition, and I explore this through my studies in artificial intelligence, human-computer interaction, and neuroscience. 
                    </p>
                    <div>
                        <img src={require('../assets/images/skiing.jpg')} alt="Parker Anderson" className="border"/>
                        <p className="text-right">First time skiing / Beech Mountain</p>
                    </div>
                </div>
                <div className="headliner flex-parent-row medium-gap medium-margin">
                    <div>
                        <img src={require('../assets/images/concert.jpg')} alt="Parker Anderson" className="border" />
                        <p className="text-left">Muse concert / Shaky Knees 2023</p>
                    </div>
                    <p className="flex-child-column">
                        Beyond this, I also leverage data-driven insights to identify opportunities and optimize financial strategies in high-stakes athletic environments (I sports gamble) and
                        I analyze player performance and refine approaches to gain a competitive edge in time-sensitive virtual environments (I play video games). 
                        I'm a big Atlanta Falcons fan and play games like Civilization 6.
                    </p>
                </div>
                <div className="headliner flex-parent-column medium-gap medium-margin">
                    <p className="flex-child-column">
                    It's a fact of life that some people will never work in a field they truly love. 
                    Some passions can't turn into careers; some passions won't even be found beneath the pile of anxiety in trying new things. 
                    When I look at my life, I can't help but think: How lucky am I to be who I am where I am.
                    </p>
                    <div>
                        <img src={require('../assets/images/lecture.jpg')} className="large-image border" alt="Parker Anderson" />
                        <p className="text-center">SQL Injection Lecture / UGA SCS</p>
                    </div>
                    
                </div>
            </div>
            <span className="divider"></span>
            <div className="side-content flex-parent-column flex-wrap-center medium-gap">
                <div className="quote flex-child-column small-gap">
                    <img src={require('../assets/images/train.jpg')} className="border"></img>
                    <div className="sub-margin">
                        <p>"Nothing in this world can take the place of persistence. Talent will not: nothing is more common than unsuccessful men with talent. Genius will not; unrewarded genius is almost a proverb... persistence and determination alone are omnipotent."</p>
                        <p className="text-right">- Calvin Coolidge</p>
                    </div>
                </div>
                <div className="flex-child-column small-gap">
                    <span className="line"></span>
                    <h3>Opinion</h3>
                </div>
                <div className="flex-child-column small-gap">
                    <div className="article flex-child-row small-gap" onClick={() => window.open('https://www.linkedin.com/in/parker-anderson-baab0820a', '_blank')}>
                        <img src={require('../assets/images/linkedin.png')} alt="Parker Anderson" className="border" />
                        <div className="sub-margin">
                            <p>LinkedIn professional describes his account: "one of the most influential ever"</p>
                            <p>3 MIN READ</p>
                        </div>
                    </div>
                    <div className="article flex-child-row small-gap" onClick={() => window.open('https://github.com/parkeranderson130', '_blank')}>
                        <img src={require('../assets/images/github.png')} alt="Parker Anderson" className="border" />
                        <div className="sub-margin">
                            <p>"Sometimes when I need a boost, I look at his GitHub..."</p>
                            <p>3 MIN READ</p>
                        </div>
                    </div>
                    <div className="article flex-child-row small-gap" onClick={() => window.open('https://drive.google.com/file/d/1qdanfjeTAxatYW1Dtb1fuG3fH09gg0k2/view', '_blank')}>
                        <img src={require('../assets/images/resume.png')} alt="Parker Anderson" className="border" />
                        <div className="sub-margin">
                            <p>10 tips and tricks to help you get a resume more like Parker's</p>
                            <p>2 MIN READ</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;