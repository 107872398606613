import React from 'react';
import { Link } from 'react-router-dom';
import './styles/navbar.scss';

const Navbar = () => {
    return (
        <div className="Navbar flex-parent-column small-margin">
            <div className="flex-child-row medium-gap">
                <p>COMPUTER SCIENCE</p>
                <p>COGNITIVE SCIENCE</p>
                <p>MATHEMATICS</p>
            </div>
            <h1 className="text-center">The New Park Times</h1>
            <nav className="flex-child-row large-gap">
                <Link to="/"><span>Home</span> &or;</Link>
                <Link to="/projects"><span>Projects</span> &or;</Link>
                <Link to="/contact"><span>Contact</span> &or;</Link>
            </nav>
            <span className="double-line"></span>
        </div>
    )
}

export default Navbar;